import React from "react";

const JobOpening = ({ onApplyClick }) => {
  const jobs = [
    { title: "Telecaller", location: "Mahape-MBP, Ghansoli" },
    { title: "HR Executive", location: "Mahape-MBP, Ghansoli" },
    { title: "Digital Marketing Manager", location: "Mahape-MBP, Ghansoli" },
    { title: "Sales Manager", location: "Mahape-MBP, Ghansoli" },
    { title: "Graphic Designer", location: "Mahape-MBP, Ghansoli" },
    { title: "IT-Head", location: "Mahape-MBP, Ghansoli" },
  ];

  return (
    <div className="w-full lg:w-[50%] lg:mx-16">
      <div className="mb-10">
        <h1 className="text-[#28aa4a] font-bold text-3xl font-lato text-start">
          Current Openings
        </h1>
      </div>

      <ul className="space-y-4">
        {jobs.map((job, index) => (
          <li
            key={index}
            className="job-preview bg-white border border-green-300 rounded-lg p-6 transition-shadow hover:shadow-lg flex justify-between items-center"
          >
            <div className="flex flex-col items-start">
              <h4 className="job-title text-start lg:text-xl font-semibold font-Manrope">
                {job.title}
              </h4>
              <h5 className=" text-gray-500 font-Manrope">{job.location}</h5>
            </div>
            <a
              href="#form-career"
              className="btn btn-apply py-2 px-2.5 lg:px-8 text-xs lg:text-sm font-extrabold uppercase tracking-wide border-2 border-green-800 text-green-800 rounded-full hover:bg-green-800 hover:text-white transition font-lato"
              onClick={() => onApplyClick(job.title)}
            >
              Apply <i className="fa fa-arrow-right" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JobOpening;
